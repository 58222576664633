import React from 'react';

import { useState, useEffect} from 'react'

import Sidebar from './sidebar'
import Topbar from './topbar'


const Layout = ({ children }) => {

  const [items, setItems] = useState([]);
  


  return (
    <>
  
        <div className="flex h-screen">

          {/* Sidebar */}
          <div className="bg-gray-800 text-white w-1/8 p-4">
              <Sidebar />
          </div>
          
          {/* Main Content wrapper */}
          <div className="flex-1 flex flex-col overflow-hidden">

            {/* <!-- Topbar --> */}
            <header className="bg-gray-700 text-white p-4">
                <Topbar />
            </header>

            <div className="flex flex-col flex-grow w-screen md:w-full min-h-screen p-4">
                {children}
            </div>

          </div>
          
      </div>
    </>
  );
}

export default Layout;