import React from 'react'

import SignupForm from '../../components/signupform'


const SignupPage = () => {

    return (
        <div className="h-screen flex items-center justify-center">
            <SignupForm></SignupForm>
        </div>
    )

}

export default SignupPage