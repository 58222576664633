
import { useFormik } from 'formik';
import { useEffect, useState } from 'react'

import { Link } from 'react-router-dom';

import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails
  } from "amazon-cognito-identity-js";


import CircularProgress from '@mui/material/CircularProgress';

const LoginForm = () => {

    const [tenant, setTenant] = useState(undefined)

    const [userPool, setUserPool] = useState(undefined)
    const [userPoolIsLoading, setUserPoolIsLoading] = useState(false)
    const [newPasswordRequired, setNewPasswordRequired] = useState(false)
    const [user, setUser] = useState(undefined)
    const [userPassLocked, setUserPassLocked] = useState(false)

    const [userEmail, setUserEmail] = useState(true)
    const [newPasswordMatch, setNewPasswordMatch] = useState(true)
    

    function ButtonLinkToSignup() {
        return (<>
            <Link to="/signup">
                <button type="submit" className="bg-gray-600 hover:bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"> Register </button>
            </Link>
            </>
        )
    }

    const getUserPool = async () => {

        setUserPoolIsLoading(true) 
        console.log(`hämtar user pool för domän: ${tenant}`)
        if (tenant) {

        // Hämta cognito parametrar från acecloud api tenants/
        const url = `${process.env.REACT_APP_ACECLOUD_API}/idp_details/?` + new URLSearchParams({"TenantName": tenant})
        const response = await fetch(url)

        if (response.status === 200) {
            const responseJson = await response.json()
            const cognitoParams = responseJson

            var pool = new CognitoUserPool({
                UserPoolId: cognitoParams.userPoolId,
                ClientId: cognitoParams.webClientId
            })
            return pool
        }
        return pool
    }}



    // Fetch cognito pool when tenant is set
    useEffect(() => {
        const fetchCognitoPool = async () => {
            if (tenant != undefined) {
                console.log(`tenant är vald: ${tenant}`)
                const pool = await getUserPool()
                console.log(pool)
                setUserPool(pool)
                if (pool != undefined) {
                    setUserPoolIsLoading(false)
                    localStorage.setItem('tenant', tenant)
                    localStorage.setItem('cognitoUserPool', JSON.stringify(pool))
                }
            }

        }
    fetchCognitoPool()
    }, [tenant])



    // When submit is clicked in the tenant selector.
    const tenantSelect = useFormik({
        initialValues: {
            tenant: ""
        },
        onSubmit: async values => {
            setTenant(values.tenant)
        }
    });



    // When Login is clicked
    const loginForm = useFormik({
        initialValues: {
            email: '',
            password: '',
            new_password: undefined,
            confirm_new_password: undefined
        },
        onSubmit: async values => {
            
            console.log(`loggar in user: ${values.email}`)
            console.log(values)
            setUserEmail(values.email)

                const user = new CognitoUser(
                    {
                        Username: values.email,
                        Pool: userPool
                    }
                )

                console.log(user)

                const authDetails = new AuthenticationDetails(
                    {
                        Username: values.email,
                        Password: values.password
                    }
                )

                user.authenticateUser(authDetails, {
                    onSuccess: function (result) {
                        var accessToken = result.getAccessToken().getJwtToken();
                        var idToken = result.idToken.jwtToken;
                        window.location.replace("/#/")
                    },
                    onFailure: function (err) {
                        
                        if (err.name === "NotAuthorizedException") {
                            console.log("Fel användarnamn eller lösenord")
                        } else {console.log(err)}
                        
                        // alert(err)
                    },
                    newPasswordRequired: function (userAttributes) {
                        console.log("Måste sätta nytt password!")
                        delete userAttributes.email;
                        setNewPasswordRequired(true)
                        
                        if (values.new_password != undefined) {
                            console.log(`Sätter pw till ${values.new_password}`)
                            user.completeNewPasswordChallenge(values.new_password, userAttributes, {
                                onFailure: err => console.log(err),
                                onSuccess: result => {
                                    console.log(result)
                                    window.location.replace("/#/")
                                }
                                
                            })
                        }
                    }
                })
        }
    });


    return (
    <>
    <div className="flex flex-col ">

        {/* Tenant Selector */}
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={tenantSelect.handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tenant">Tenant</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="tenant"
                            name="tenant"
                            type="tenant"
                            placeholder="company.se"
                            onChange={tenantSelect.handleChange}
                            value={tenantSelect.values.tenant}
                        />
                </div>
                <div className="flex items-center justify-between">
                    <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Submit 
                    </button>

                    <div className="flex">
                        {!userPoolIsLoading ? <></> : 
                            <CircularProgress />
                        }
                        
                    </div>
                    
                </div>
            </form>
                

            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={loginForm.handleSubmit}>
            {/* Email */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        name="email"
                        type="email"
                        placeholder="john.doe@company.se"
                        onChange={loginForm.handleChange}
                        value={loginForm.values.email}
                        autoComplete="email"
                        disabled={userPoolIsLoading}
                    />
                </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Password </label>
                    <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        name="password"
                        type="password"
                        placeholder="******************"
                        onChange={loginForm.handleChange}
                        value={loginForm.values.password}
                        autoComplete="password"
                        disabled={userPoolIsLoading}
                    />
            </div>

            {newPasswordRequired ? 
            
            <>
            {/* New Password fields */}
            <div className="block">
                <label className="block text-red-900 text-sm font-bold mb-2" >You need to update your password! </label>
            </div>

            
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">New password </label>
                    <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="new_password"
                        name="new_password"
                        type="password"
                        placeholder="******************"
                        onChange={loginForm.handleChange}
                        value={loginForm.values.new_password}
                        autoComplete="password"
                        disabled={userPoolIsLoading}
                    />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Confirm new password </label>
                    <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="confirm_new_password"
                        name="confirm_new_password"
                        type="password"
                        placeholder="******************"
                        onChange={loginForm.handleChange}
                        value={loginForm.values.confirm_new_password}
                        autoComplete="password"
                        disabled={userPoolIsLoading}
                    />
            </div>


            </>
        
            :
            <></>
            }



            <div className="flex items-center justify-between">
                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Submit 
                </button>
            </div>

            </form>

            <div className="mb-4">
                <label className="block text-sm font-bold text-gray-700 ">Not registered yet? <ButtonLinkToSignup /> </label>
                

            </div>

            
        </div>
    </>
)

};

export default LoginForm