
import React from 'react'
import { Link } from 'react-router-dom'


import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    ListItemSuffix,
    ListItemText,
    Chip,
    Grid
  } from "@material-tailwind/react";

// Icons
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

const Sidebar = () => {

    const sidebarItems = [
        { path: "/", text: "Dashboard", icon: <DashboardOutlinedIcon/> },
        { path: "/inventory", text: "Inventory", icon: <Inventory2OutlinedIcon /> },
        { path: "/users", text: "Users", icon: <GroupOutlinedIcon /> }
    ]

  return (
    <>
    <sidebar className ="sm:w-1/5 bg-blue-800 min-h-screen">
        <div className="sticky top-0 pt-12 px-2 w-full">
            <List>
                { sidebarItems.map(({path, text, icon}, index) => {
                    return (
                        <ListItem className="hover:text-blue-500" key={index}>
                             <Link to={path}> {icon} {text}</Link>
                        </ListItem>
                    );
                })}
            </List>
        </div>
    </sidebar>
    </>
  )
}

export default Sidebar



