
import { useFormik } from 'formik';

import {useState, useNavigation} from 'react'

import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser
  } from "amazon-cognito-identity-js";


const SignupForm = () => {
    const [tenantFree, setTenantFree] = useState(true)

    const tryRegisterTenant = async (email) => {
        var domain = email.split("@").pop()
        console.log(`registrerar ny tenant: ${email}, domain: ${domain}`)

        const url = `${process.env.REACT_APP_ACECLOUD_API}/v1/register/`
        const payload = {"user_email": email}

        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            mode: "cors"
            }
        )

        if (response.status === 409) {
            console.log("Tenant upptagen!")
            setTenantFree(false)
        }
    }

 
    // When form is submitted
    const formik = useFormik({
        initialValues: {
        firstname: '',
        lastname: '',
        email: '',
        validationcode: ''
        },
        onSubmit: values => {

            tryRegisterTenant(values.email)
        }})
    
            // // Define userPool
            // const userPool = new CognitoUserPool({
            //     UserPoolId: process.env.REACT_APP_USERPOOL_ID,
            //     ClientId: process.env.REACT_APP_APPCLIENT_ID
            //     })
        
        



            // userPool.signUp(values.email, undefined, attributeList, null, (err, result) => {
            //     if (err) {
            //         console.log(err);
            //         return;
            //     }
            //     setAwaitsConfirmation(true)
            //     console.log(`call result: ${result}`)
            // })

            // const cognitoUser = new CognitoUser(
            //     {
            //         Username: values.email,
            //         Pool:userPool
            //     }
            // )

            // const authenticationData = {
            //     Username: values.email,
            //     ConfirmationCode: values.validationcode
            // }

            // cognitoUser.confirmRegistration(values.validationcode, undefined, (err, result) => {
            //     if(err) {
            //         console.log(err)
            //     }
            //     else {
            //         console.log(result)
            //         if (result === "SUCCESS") {
            //             window.location.replace('/#/login')
            //         }
            // //     }
            // })
        // },
    // });



    return (
    <>
        <div className="flex flex-col">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={formik.handleSubmit}>

            {/* First Name */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstname">First Name</label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="firstname"
                        name="firstname"
                        type="firstname"
                        placeholder="john"
                        onChange={formik.handleChange}
                        value={formik.values.firstname}
                        autoComplete="given-name"
                    />
            </div>

            {/* Last Name */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastname">Last Name</label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="lastname"
                        name="lastname"
                        type="lastname"
                        placeholder="doe"
                        onChange={formik.handleChange}
                        value={formik.values.lastname}
                    />
            </div>

            {/* Tenant
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastname">Tenant</label>
                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="tenant"
                        name="tenant"
                        type="tenant"
                        placeholder="company"
                        onChange={formik.handleChange}
                        value={formik.values.tenant}
                    />
            </div> */}

            {/* Email */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>

                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        name="email"
                        type="email"
                        placeholder="john.doe@domain.io"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        autoComplete="email"
                    />
            </div>

            {/* Phone */}
            {/* <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Phone number</label>

                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="phone"
                        name="phone"
                        type="phone"
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                    />
            </div> */}

            {/* Password */}
            {/* <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Password </label>
                    <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        name="password"
                        type="password"
                        placeholder="******************"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        autoComplete="new-password"
                    />
            </div> */}

            
            <div className="flex items-center justify-between">
                <button type="submit" className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Register
                </button>
            </div>


            {/* <>
                <br></br>
                <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">Validation Code </label>
                    <input className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="validationcode"
                        name="validationcode"
                        type="text"
                        placeholder="******************"
                        onChange={formik.handleChange}
                        value={formik.values.validationcode}
                        autoComplete="new-password"
                    />
                </div>


                <div className="flex items-center justify-between">
                    <button type="submit" className="bg-blue-500 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Validate
                    </button>
                </div>
            </> */}

        {tenantFree != true &&
            <>
                <br></br>
                <div className="mb-4">
                    <label className="block text-red-700 text-sm font-bold mb-2">Tenant is already registered!</label>
                </div>

                <a href="/#/login">Login</a>
            </>
            }

            </form>

            

    </div>
    </>
    );

};

export default SignupForm