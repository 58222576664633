
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/acebit_tri.png";
import LogoutIcon from '@mui/icons-material/Logout';

import {useState, useEffect} from 'react'
import {getCurrentUser} from '../utils/currentUser'

const Topbar = () => {


  const getUserName = () => {
    var user = getCurrentUser()
    if (user) {
      var username = user.username
      return username
    } else {
      return null
    }
  }

  const isLoggedIn = () => {
    var user = getCurrentUser()
    if(user) {
      return true
    }
    return false
  }


  const logOut = () => {
    console.log("Loggar ut! ")
    var user = getCurrentUser()
    console.log(user.signOut())
    window.location.href = "#/login"
  }
 
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      setUserName(getUserName())
    } else {
      window.location.replace("/#/login")
    }
  });

  return (
    <>
      <div className="flex justify-between">

        <div className="flex items-center justify-start">
          <a href="/">
            <h3>Welcome to the future</h3>
          </a>
        </div>

        <div className="flex items-center justify-end">
            {userName} 
            <div className="flex px-2">
              <button type="button" tooltip="Logout" onClick={logOut} data-popover-target="popover-default" className="font-medium text-sm px-2.5 ">
                  <LogoutIcon />
                </button>

            </div>
        </div>


      </div>
    </>
  );
};

export default Topbar;