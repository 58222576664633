
import Sidebar from './components/sidebar'

import HomePage from "./pages/home/page";
import InventoryPage from "./pages/inventory/page";
import LoginPage from './pages/login/page';
import SignupPage from './pages/signup/page';
import NotFoundPage from "./pages/notfound/page";
import UsersPage from "./pages/users/page"


import React from "react";
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";

import {getCurrentUser, userLoggedIn} from "./utils/currentUser"

function App() {

  document.title = "Acecloud"

  const currentUser = getCurrentUser()

  if (!userLoggedIn())  {
    window.location.replace("/#/login")
  }




  return (
    <>
    <div className = "image"
      style = {{
        backgroundImage:'url(background_2.png)',
        height: "100vh",
        width: "100vw",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover"
      }}
      >
      <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/inventory" element={<InventoryPage/> } />
          <Route path="/users" element={<UsersPage/> } />
          <Route path="/login" element={<LoginPage/> } />
          <Route path="/signup" element={<SignupPage/> } />
      </Routes>
      </div>
    </>
  )

}

export default App;