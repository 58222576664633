import React from 'react'

import Layout from '../../components/layout'

const UsersPage = () => {
  return (
    <Layout>
      <div>UsersPage</div>
    </Layout>
  )
}

export default UsersPage