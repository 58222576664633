
import React from 'react'
import { Link } from "react-router-dom";
import Layout from '../../components/layout'


const inventorypage = () => {
  return (
    <Layout>
    <div>
      <h1>Inventory page!</h1>
    </div>
    </Layout>
  )
}


export default inventorypage