

import React from 'react'
import { Link } from "react-router-dom";
import Layout from '../../components/layout'


export default function HomePage() {
  return (
    <Layout>
      <div className="Home">
        Homepage!
      </div>
    </Layout>
  )
}
