import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails
  } from "amazon-cognito-identity-js";

const cognitoUserPool = () => {
    const tenant = localStorage.getItem('tenant')
    const userPoolInStorage = JSON.parse(localStorage.getItem('cognitoUserPool'))
    if (!userPoolInStorage) {
      console.log("ingen inloggad")
      return undefined
    }
    const userPoolData = {
      UserPoolId: userPoolInStorage.userPoolId,
      ClientId: userPoolInStorage.clientId
    }
    const cognitoPool = new CognitoUserPool(userPoolData)
    return cognitoPool
  }

const checkIfExpired = (time) => {
  return time < Date.now()/ 1000
}


export const getCurrentUser = () => {
  const pool = cognitoUserPool()
  if (pool != undefined) {
    const currentUser = pool.getCurrentUser()
    return currentUser
  }
  return undefined
}


export const userLoggedIn = () => {
  const user = getCurrentUser()


  if (user) {
    const expirationTime = user.getSession(function(err, session){
      if (err) {
        console.log(err)
      }
      const expirationTime = session.getIdToken().getExpiration()
      console.log(session.getIdToken())
      return expirationTime
    })

    // Check if id token expired
    if(checkIfExpired(expirationTime)) {
      return false
    }
    return true
  }
  else {
    return false
  }
}